import React from 'react';
import glamorous from 'glamorous';
import {BLACK, CHARCOAL, FOCUS_BLUE, WHITE} from '../../../shared/style/colors';
import {BASE_TEXT, FONTS, WEIGHT} from '../../../shared/style/typography';
import {LAPTOP, PHONE, TABLET, DESKTOP} from '../../../shared/style/breakpoints';
import GhostButton from '../../../shared/library/buttons/base/ghost';
import SimpleButton from '../../../shared/library/buttons/base/simple';
import {MAX_PAGE_WIDTH} from '../../../shared/style/dimensions';
import TechStackFilePillIcon from '../../../shared/library/icons/introducing_the_stack_file_desktop.svg';

const Container = glamorous.div(({background = WHITE}) => ({
  background: background,
  margin: '70px auto 90px',
  padding: '0 15px',
  [DESKTOP]: {
    padding: 0
  },
  [TABLET]: {
    padding: 0
  },
  [PHONE]: {
    padding: 0,
    margin: '54px auto 0px',
    marginBottom: 50
  }
}));

const SubContainer = glamorous.div(
  {
    ...BASE_TEXT,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    margin: '0 auto',
    padding: 0
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const InnerDiv = glamorous.div({
  margin: '0px auto',
  width: '100%',
  [LAPTOP]: {
    textAlign: 'center'
  }
});

const Heading = glamorous.h1({
  ...BASE_TEXT,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontStyle: 'normal',
  textAlign: 'center',
  fontSize: 57,
  fontWeight: WEIGHT.ULTRA_BLACK,
  lineHeight: '72px',
  color: CHARCOAL,
  maxWidth: 1160,
  margin: '18px auto 28px',
  [LAPTOP]: {
    margin: '18px auto 16px',
    fontSize: 55
  },
  [PHONE]: {
    fontSize: 28,
    lineHeight: '36px',
    margin: '18px auto 25px'
  }
});

const HeadingSubTitle = glamorous.div({
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontStyle: 'normal',
  textAlign: 'center',
  fontSize: 30,
  fontWeight: WEIGHT.ULTRA_BLACK,
  lineHeight: '42px',
  color: CHARCOAL,
  maxWidth: MAX_PAGE_WIDTH,
  margin: 'auto',
  marginBottom: 15,
  [LAPTOP]: {
    margin: '0 auto',
    marginBottom: 16,
    fontSize: 28,
    width: 707
  },
  [PHONE]: {
    fontSize: 18,
    maxWidth: 280,
    lineHeight: '24px'
  }
});

const SubHeading = glamorous.div({
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontStyle: 'normal',
  fontWeight: WEIGHT.NORMAL,
  fontSize: 24,
  textAlign: 'center',
  lineHeight: '44px',
  color: BLACK,
  margin: 'auto',
  [DESKTOP]: {
    maxWidth: 930
  },
  [LAPTOP]: {
    margin: '0 auto',
    marginBottom: 16,
    width: 741
  },
  [PHONE]: {
    fontSize: 18,
    lineHeight: '33px',
    maxWidth: 300
  }
});

const ButtonContainer = glamorous.div({
  maxWidth: MAX_PAGE_WIDTH,
  margin: 'auto',
  marginTop: 35,
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'center',
  [LAPTOP]: {
    width: 'fit-content',
    margin: '30px auto 0'
  },
  [PHONE]: {
    width: 'auto',
    flexDirection: 'column'
  }
});

const BrowseTools = glamorous(SimpleButton)({
  border: `1px solid #008FF9`,
  borderRadius: 4,
  height: 58,
  fontSize: 20,
  fontWeight: WEIGHT.ULTRA_BOLD,
  textAlign: 'center',
  backgroundColor: FOCUS_BLUE,
  color: WHITE,
  padding: '0 41px',
  minWidth: 'auto',
  letterSpacing: 0,
  [PHONE]: {
    width: 345,
    height: 50,
    marginLeft: 0,
    padding: 0,
    margin: 'auto',
    marginTop: 20
  }
}).withComponent('a');

const BrowseStacks = glamorous(GhostButton)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 191,
  padding: '0 15px',
  height: 50,
  fontSize: 20,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  zIndex: 99,
  marginLeft: 31,
  fontWeight: WEIGHT.ULTRA_BOLD,
  ':focus': {
    background: FOCUS_BLUE,
    color: WHITE
  },
  ':hover': {
    background: FOCUS_BLUE,
    color: WHITE
  },
  [DESKTOP]: {
    height: 58
  },
  [PHONE]: {
    width: 345,
    height: 50,
    marginLeft: 0,
    padding: 0,
    margin: 'auto',
    marginTop: 20
  }
}).withComponent('a');

const ImageContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 25,
  [PHONE]: {
    padding: '0 20px'
  }
});

const HeadingComponent = () => {
  return (
    <Container>
      <SubContainer>
        <InnerDiv>
          <ImageContainer>
            <a
              href="/posts/stackshare-is-now-part-of-fossa"
              title="StackShare is now part of FOSSA"
            >
              <TechStackFilePillIcon />
            </a>
          </ImageContainer>
          <Heading>The Largest Developer Community for Tech Stacks</Heading>
          <HeadingSubTitle>See what tech stacks your peers are using</HeadingSubTitle>
          <SubHeading>
            More than 1M developers have shared their public tech stacks, favorite tools, advice,
            and opinions. Search now to find the latest alternatives in your stack or join the
            community to get updates and contribute your expertise.
          </SubHeading>

          <ButtonContainer>
            <BrowseTools href={`/tools/trending`}> Browse Tools </BrowseTools>
            <BrowseStacks href={`/stacks`} color={FOCUS_BLUE}>
              Browse Stacks
            </BrowseStacks>
          </ButtonContainer>
        </InnerDiv>
      </SubContainer>
    </Container>
  );
};

export default HeadingComponent;
